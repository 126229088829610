export function duration(value) {
  if (!isFinite(value)) return String(value);
  var sign = '';

  if (value < 0) {
    sign = '-';
    value = Math.abs(value);
  } else {
    value = Number(value);
  }

  var sec = value % 60;
  var parts = [Math.round(sec) === sec ? sec : sec.toFixed(3)];

  if (value < 60) {
    parts.unshift(0);
  } else {
    value = Math.round((value - parts[0]) / 60);
    parts.unshift(value % 60);

    if (value >= 60) {
      value = Math.round((value - parts[0]) / 60);
      parts.unshift(value);
    }
  }

  var first = parts.shift();
  return sign + first + ':' + parts.map(function (n) {
    return n < 10 ? '0' + String(n) : String(n);
  }).join(':');
}