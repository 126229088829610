export function time(v, lc, p) {
  var o = {
    second: 'numeric',
    minute: 'numeric',
    hour: 'numeric'
  };

  switch (p) {
    case 'full':
    case 'long':
      o.timeZoneName = 'short';
      break;

    case 'short':
      delete o.second;
  }

  return new Date(v).toLocaleTimeString(lc, o);
}