export function _nf(lc) {
  return _nf[lc] || (_nf[lc] = new Intl.NumberFormat(lc));
}
export function number(lc, value, offset) {
  return _nf(lc).format(value - offset);
}
export function strictNumber(lc, value, offset, name) {
  var f = _nf(lc).format(value - offset);

  if (f === 'NaN') throw new Error('`' + name + '` or its offset is not a number');
  return f;
}
export function plural(value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
}
export function select(value, data) {
  return {}.hasOwnProperty.call(data, value) ? data[value] : data.other;
}