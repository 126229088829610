export function numberFmt(value, lc, arg, defaultCurrency) {
  var a = arg && arg.split(':') || [];
  var opt = {
    integer: {
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent'
    },
    currency: {
      style: 'currency',
      currency: a[1] && a[1].trim() || defaultCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  };
  return new Intl.NumberFormat(lc, opt[a[0]] || {}).format(value);
}
export function numberCurrency(value, lc, arg) {
  return new Intl.NumberFormat(lc, {
    style: 'currency',
    currency: arg,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
}
export function numberInteger(value, lc) {
  return new Intl.NumberFormat(lc, {
    maximumFractionDigits: 0
  }).format(value);
}
export function numberPercent(value, lc) {
  return new Intl.NumberFormat(lc, {
    style: 'percent'
  }).format(value);
}