export function date(v, lc, p) {
  var o = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  switch (p) {
    case 'full':
      o.weekday = 'long';

    case 'long':
      o.month = 'long';
      break;

    case 'short':
      o.month = 'numeric';
  }

  return new Date(v).toLocaleDateString(lc, o);
}